<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <c-table
        ref="grid1"
        title="하도급 업체 목록"
        tableId="grid1"
        :columns="grid1.columns"
        :data="grid1.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        @rowClick="rowClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <c-table
        ref="grid2"
        title="업체별 공종"
        tableId="grid2"
        :columns="grid2.columns"
        :data="grid2.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        @rowClick="rowClick2"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid3"
        title="업체별 공종별 투입인력"
        tableId="grid3"
        :columns="grid3.columns"
        :data="grid3.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        selection="multiple"
        rowKey="pjmProjectPersonId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && selectedProcess.pjmProjectVendorId" label="추가" icon="add" @btnClicked="addrow" />
            <c-btn v-if="editable && selectedProcess.pjmProjectVendorId" label="저장" icon="save" @btnClicked="saveMat" />
            <c-btn v-if="editable && selectedProcess.pjmProjectVendorId" label="삭제" icon="remove" @btnClicked="deleteMat" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid1: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공종명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        columns: [
          {
            name: 'workStartDt',
            field: 'workStartDt',
            label: '투입일',
            align: 'center',
            type: 'date',
            sortable: false,
          },
          {
            name: 'workEndDt',
            field: 'workEndDt',
            label: '철수일',
            align: 'center',
            type: 'date',
            sortable: false,
          },
          {
            name: 'userCnt',
            field: 'userCnt',
            label: '투입인력(명)',
            align: 'center',
            style: 'width: 150px',
            type: 'number',
            sortable: false,
          },
        ],
        data: [],
      },
      selectedVendor: {},
      selectedProcess: {},
      editable: true,
      list1Url: '',
      list2Url: '',
      list3Url: '',
      save3rl: '',
      delete3Url: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'param.updateFlag'() {
      this.getList1();
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.list1Url = selectConfig.pjm.vendor.list.url;
      this.list2Url = selectConfig.pjm.vendor.process.list.url;
      this.list3Url = selectConfig.pjm.vendor.process.person.url;
      this.save3Url = transactionConfig.pjm.vendor.process.person.save.url;
      this.delete3Url = transactionConfig.pjm.vendor.process.person.delete.url;
      this.getList1();
    },
    rowRemoveSelect0() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    rowRemoveSelect1() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    rowRemoveSelect2() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[2];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList1() {
      // 도급업체 조회
      if (this.param.plantCd) {
        this.rowRemoveSelect0();
        this.selectedVendor = {};
        this.selectedProcess = {};
        this.grid2.data = [];
        this.grid3.data = [];
        this.$http.url = this.list1Url;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.param.plantCd
        }
        this.$http.request((_result) => {
          this.grid1.data = _result.data;
        },);
      }
    },
    rowClick(row) {
      this.selectedVendor = row;
      this.selectedProcess = {};
      this.grid3.data = [];
      this.rowRemoveSelect1();
      // 업체별 공종 조회
      this.$http.url = this.list2Url;
      this.$http.param = {
        pjmProjectVendorId: row.pjmProjectVendorId,
        vendorCd: row.vendorCd,
        processCds: row.processCds.split(',')
      }
      this.$http.type = 'GET';
      this.$http.request(
        (_result) => {
          this.grid2.data = _result.data;
        },
        () => {}
      );
    },
    rowClick2(row) {
      this.rowRemoveSelect2();
      this.selectedProcess = row;
      // 업체별 공종별 투입인력 조회
      this.$http.url = this.list3Url;
      this.$http.param = {
        pjmProjectVendorId: row.pjmProjectVendorId,
        processCd: row.processCd,
      }
      this.$http.type = 'GET';
      this.$http.request(
        (_result) => {
          this.grid3.data = _result.data;
        },
        () => {}
      );
    },
    addrow() {
      this.grid3.data.push({
        editFlag: 'C',
        pjmProjectPersonId: uid(),  // 공종별 투입인력 일련번호
        pjmProjectVendorId: this.selectedVendor.pjmProjectVendorId,  // 하도급 일련번호
        plantCd: this.selectedVendor.plantCd,  // 공사현장코드
        vendorCd: this.selectedVendor.vendorCd,  // 업체코드
        processCd: this.selectedProcess.processCd,  // 공종코드
        userCnt: '',  // 업체투입인력(명)
        workStartDt: '',  // 투입일
        workEndDt: '',  // 철수일
      })
    },
    saveMat() {
      let checkItem = ['userCnt', 'workStartDt', 'workEndDt']
      let isConti = true;
      this.$_.forEach(this.grid3.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [업체투입인력, 투입일, 철수일]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid3.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.save3Url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.rowClick2(this.selectedProcess)
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteMat() {
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete3Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList1();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>